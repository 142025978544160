import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoginModal from "../components/sales-pages/loginModal"
import Hero from "../components/sales-pages/hero"
import VSL from "../components/sales-pages/vsl"
import Bio from "../components/mentorBio"
import FAQ from "../components/faq"
import LiveChat from "../components/sales-pages/liveChat"
import CTAButton from "../components/sales-pages/ctaButton"
import LectureList from "../components/lectureList"
import { useStaticQuery, graphql } from "gatsby"
import { useAuth } from "gatsby-theme-firebase"
import "../styles/education.scss"
import "../styles/content.scss"
import publicLectureInfo from "../lectures/first-30-days"

export default ({ pageContext: { course } }) => {
  const { title, subtitle, slug } = course
  const [toggleLogin, setToggleLogin] = React.useState(false)

  const { isLoggedIn } = useAuth()
  const images = useStaticQuery(graphql`
    query {
      hero: file(
        absolutePath: { regex: "/education/30days/30days-hero.jpg/" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(absolutePath: { regex: "/education/30days.jpg/" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title={`${title}: ${subtitle}`}
        description={subtitle}
        image={images.logo.childImageSharp.fixed.src}
      />
      {toggleLogin && (
        <LoginModal
          isLoggedIn={isLoggedIn}
          course={course}
          slug={slug}
          setToggleLogin={setToggleLogin}
        />
      )}
      <Hero
        image={images.hero.childImageSharp.fluid}
        title={title}
        subtitle={subtitle}
        button={
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        }
      />{" "}
      <div className="course-sales-content">
        <div className="row">
          <div className="column50">
            <VSL vidLink="408897405" />{" "}
          </div>
          <div className="column50">
            <p className="video-explainer">
              Imagine obtaining the mindset of some of the most successful
              people on the planet. Over the next 30 days, <b>you will.</b>
              <CTAButton
                isLoggedIn={isLoggedIn}
                slug={slug}
                setToggleLogin={setToggleLogin}
              />
            </p>
          </div>
        </div>
        <div id="content">
          <p>
            Whether you want wealth, love, happiness, fulfillment, or success of
            any kind; it all begins with mindset. Practicing and committing to
            improving your mindset is imperative to achieve any kind of success
            in this world.
          </p>
          <p>
            Only the top of the top achieve the &ldquo;
            <em>Millionaire Mindset</em>&rdquo;, and only those who actively
            invest in and study the habits of successful people will truly
            attain this <strong>elusive mindset</strong>. So if you&rsquo;re
            sick of being a drone or just another &lsquo;average&rsquo; person
            &ndash; if you&rsquo;re <u>tired of the normalcy</u> of day to day
            life and want to expand your mind:{" "}
            <strong>the time to level up is now</strong>.
          </p>
          <p>&nbsp;</p>
          <p>Over the next 30 days you will uncover how to:</p>
          <ul className="fancy-checks">
            <li>
              Follow the road to happiness and fulfillment&nbsp;
              <strong>
                <em>your own way</em>{" "}
              </strong>{" "}
              .
              <ul className="fancy-checks">
                <li>
                  How to use &lsquo;<em>Telos</em>&rsquo; to find your true
                  calling in life.
                </li>
                <li>
                  Why emulating your &lsquo;hero&rsquo; will cause you{" "}
                  <u>nothing but pain</u>.
                </li>
                <li>
                  The use of <strong>S.M.A.R.T.</strong> goals to plan out a
                  long-term game plan.
                </li>
                <li>
                  How to stick to your plan by breaking it down into day-to-day
                  executional steps.
                </li>
              </ul>
            </li>
            <li>
              Attain <em>control</em> over all areas of your life.
              <ul className="fancy-checks">
                <li>Persuasion techniques from the masters of debate.</li>
                <li>
                  Managing resources like time and money in the most efficient
                  ways.
                </li>
                <li>
                  Guarding your &lsquo;sphere of influence&rsquo; to remove
                  negativity from your life.
                </li>
                <li>
                  Communicating effectively to increase leverage in every
                  conversation.
                </li>
              </ul>
            </li>
            <li>
              Invest time &amp; money for the{" "}
              <strong>highest paying dividends</strong>.
              <ul className="fancy-checks">
                <li>
                  Why traditional budgeting <em>just doesn&rsquo;t work</em>.
                </li>
                <li>
                  Blowing up your income using the &lsquo;
                  <u>expert</u>&rsquo; strategy.
                </li>
                <li>The best stock spread for an early retirement.</li>
                <li>
                  Why&nbsp;listening to naysayers&nbsp;will just hold you back
                  in the long run.
                </li>
              </ul>
            </li>
            <li>
              Round out the perfect mindset with the <strong>last 2%</strong>.
              <ul className="fancy-checks">
                <li>
                  Maxing out your daily productivity to <em>work less</em> and{" "}
                  <strong>output more</strong>.
                </li>
                <li>
                  The top 1% <u>exercise regimen</u> that will keep you healthy
                  for years to come.
                </li>
                <li>
                  The fuel your body needs to perform on peak levels all the
                  time.
                </li>
                <li>
                  Getting to the &lsquo;<strong>Good Life</strong>&rsquo; and
                  staying there.
                </li>
              </ul>
            </li>
          </ul>
        </div>{" "}
        <hr />
        <div className="row" id="results">
          <h2>Here's what some of my students had to say:</h2>
          {course.videoTestimonials.map((videoLink, i) => (
            <iframe
              key={i}
              width="50%"
              height="250"
              src={`https://player.vimeo.com/video/${videoLink}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ))}
        </div>{" "}
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        </div>
        <br />
        <br />
        <hr />
        <div className="row">
          <Bio />
        </div>
        <br />
        <hr />
        <br />
        <h2>📚&nbsp;Course Curriculum</h2>
        <LectureList
          lectureInfo={publicLectureInfo}
          chooseLecture={null}
          chosenLecture={{}}
        />{" "}
        <hr />
        <br />
        <FAQ />
        <br />
        <hr />
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        </div>
      </div>
      <LiveChat />
    </Layout>
  )
}
